<template lang="pug">
.content
    Skeleton(v-if="isLoading")
    
    template(v-else)
        .mb-16.flex.items-center
            ul.flex.items-center
                li.mr-28(v-for="tab in tabs" :key="tab")
                    TabButton(:active="activeTab === tab" @click="changeTab(tab)")
                        | {{ tab }}

            ul.ml-auto.flex.items-center
                li.mr-12
                    MultiSelectWithSearch(
                        name="Упаковка"
                        :items="amounts"
                        :has-search="false"
                        hasSelectAll
                        @select-item="selectAmount"
                        @select-all="selectAllAmounts(true)"
                        @clear-all="selectAllAmounts(false)"
                        @hide="changeInnerFilters"
                    )
                li
                    MultiSelectWithSearch(
                        name="Селлеры"
                        :items="sellers"
                        searchPlaceholder="Поиск селлеров"
                        tooltip-placement="bottom-end"
                        hasSelectAll
                        @select-item="selectSeller"
                        @select-all="selectAllSellers(true)"
                        @clear-all="selectAllSellers(false)"
                        @hide="changeInnerFilters"
                    )

        SKUGeneralInfoTable(
            v-if="activeTab === tabs[0]"
            :request-params="tableRequestParams"
            :need-update="needUpdateTable"
            @updateBrand="$emit('brand', $event)"
        )
        SKUDynamicAvailabilityTable(
            v-else
            :request-params="tableRequestParams"
            :need-update="needUpdateTable"
            @updateBrand="$emit('brand', $event)"
        )

</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Skeleton from '@/components/Nestle/Skeleton/Common.vue';
import TabButton from '@/components/BrandsAndSKU/TabButton.vue';
import MultiSelectWithSearch from '@/components/Elements/MultiSelect/MultiSelectWithSearch.vue';
import SKUGeneralInfoTable from '@/components/BrandsAndSKU/SKUGeneralInfoTable.vue';
import SKUDynamicAvailabilityTable from '@/components/BrandsAndSKU/SKUDynamicAvailabilityTable.vue';

const PAGE_TABS = ['Общая информация', 'Динамика наличия'];

export default {
    components: {
        Skeleton,
        TabButton,
        MultiSelectWithSearch,
        SKUGeneralInfoTable,
        SKUDynamicAvailabilityTable,
    },

    props: {
    requestParams: {
            type: Object,
            required: true,
        },
        needUpdate: {
            type: Boolean,
            required: true,
        },
  },

    data() {
        return {
            tabs: PAGE_TABS,
            activeTab: PAGE_TABS[0],
            needUpdateTable: false,

            isLoading: false,

            amounts: [],
            sellers: [],
            isSelectDirty: false,
        }
    },

    computed: {
        ...mapState({
          selectedSellersIds: ({ brandAndSku }) => brandAndSku.selectedSellersIds,
          selectedAmountsNames: ({ brandAndSku }) => brandAndSku.selectedAmountsNames,
        }),
        selectedAmounts() {
            return this.amounts.filter((amount) => amount.checked);
        },

        selectedSellers() {
            return this.sellers.filter((seller) => seller.checked);
        },

        tableRequestParams() {
            const params = {
                dateFrom: this.requestParams.dateFrom,
                dateTo: this.requestParams.dateTo,
                ownBrands: this.requestParams.brands,
                stores: this.requestParams.stores,
                categories: this.requestParams.categories,
                regions: this.requestParams.regions,
                subBrands: this.requestParams.subBrands,
                amounts: this.selectedAmounts.map((item) => item.name).join(','),
            };

            if (this.selectedSellers.length) {
                params.sellers = this.selectedSellers.map((item) => item.id).join(',');
            }

            return params;
        },
    },

    methods: {
        ...mapMutations('brandAndSku', [
          'setActiveTab',
          'setSelectedSellersIds',
          'setSelectedAmountsNames'
        ]),
        async fetchAmounts() {
            try {
                const response = await this.$api.common.getAmounts(this.requestParams);
                const isResponseValid = response && Array.isArray(response.list);

                this.amounts = isResponseValid 
                ? response.list.map((item, index) => ({ id: index + 1, name: item, checked: this.selectedAmountsNames.includes(item) })) 
                : [];
            } catch {
                this.amounts = [];
            }
        },

        async fetchSellers() {
            try {
                const response = await this.$api.common.getSellers(this.requestParams);
                const isResponseValid = response && Array.isArray(response.sellers);

                this.sellers = isResponseValid ? response.sellers.map((item) => ({ ...item, checked: this.selectedSellersIds.includes(item.id) })) : [];
            } catch {
                this.sellers = [];
            }
        },

        async fetchFilters() {
            this.isLoading = true;
            await Promise.all([this.fetchAmounts(), this.fetchSellers()]);
            this.isLoading = false;
        },

        changeInnerFilters() {
            if (!this.isSelectDirty) return;
            this.needUpdateTable = !this.needUpdateTable;
            this.isSelectDirty = false;
        },

        changeTab(tab) {
            this.setActiveTab(tab);
            this.activeTab = tab;
        },

        selectAmount(itemId) {
            const currentItem = this.amounts.find((item) => item.id === itemId);
            if (!currentItem) return;

            if (currentItem.checked) {
              this.setSelectedAmountsNames(this.selectedAmountsNames.filter((name) => {
                 return currentItem.name !== name
              }))
            } else {
              this.setSelectedAmountsNames([...this.selectedAmountsNames, currentItem.name])
            }

            currentItem.checked = !currentItem.checked;
            this.isSelectDirty = true;
        },

        selectAllAmounts(isChecked) {
            this.amounts.forEach((seller) => {
                seller.checked = isChecked;
            });

            if (isChecked) {
              this.setSelectedAmountsNames(this.amounts.map((seller) => seller.name))
            } else {
              this.setSelectedAmountsNames([]);
            }

            this.isSelectDirty = true;
        },

        selectSeller(itemId) {
            const currentItem = this.sellers.find((item) => item.id === itemId);
            if (!currentItem) return;

            if (currentItem.checked) {
              this.setSelectedSellersIds(this.selectedSellersIds.filter((id) => {
                 return currentItem.id !== id
              }))
            } else {
              this.setSelectedSellersIds([...this.selectedSellersIds, currentItem.id])
            }

            currentItem.checked = !currentItem.checked;
            this.isSelectDirty = true;
        },

        selectAllSellers(isChecked) {
            this.sellers.forEach((seller) => {
                seller.checked = isChecked;
            });

            if (isChecked) {
              this.setSelectedSellersIds(this.sellers.map((seller) => seller.id))
            } else {
              this.setSelectedSellersIds([]);
            }

            this.isSelectDirty = true;
        },
    },

    watch: {
    async needUpdate() {
            await this.fetchFilters();
    },
  },

    async mounted() {
        this.setActiveTab(PAGE_TABS[0]);
        await this.fetchFilters();
    },

    destroyed() {
        this.$emit('brand', '');
    },
}
</script>
